import React from 'react';
import { formatMoney } from '../../utils/FormattingUtils';
import { SideBarContent } from '../../pages/InvestmentDetails/InvestmentDrillDown';
import { NonCustodyHolding } from '../../dto/Holding';

interface ValueProps {
  totalValue: number;
  isDfm: boolean;
  setSideBar: (value: React.SetStateAction<SideBarContent>) => void;
  cashValue: number;
  totalCost: number;
}

function showCost(): boolean {
  console.log(window.location.href)
  if (window.location.href.toLowerCase().includes('quality_shares_portfolio') || window.location.href.toLowerCase().includes('wealth_club_portfolio_service')) {
    return false;
  }
  else return true;
}

function DrilldownValue(props: ValueProps) {
  return (
    <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4">
      {/* Total Value and Cash Balance */}
      <div className="flex flex-col md:flex-row items-center mt-3 md:mt-0">
        <div className="flex items-center">
          <h3>Total value:</h3>
          <h3 className="text-bond text-2xl ml-1" data-cy="totalValue">
            {formatMoney(props.totalValue)}
          </h3>
        </div>

        {props.cashValue > 0 && (
          <div className="flex items-center mt-1 md:mt-0 md:ml-4">
            <h5 className="text-sm text-gray-600" data-cy="cashValue">
              ({formatMoney(props.cashValue)} in cash balance)
            </h5>
          </div>
        )}
        {props.totalCost > 0 && showCost() && (
          <div className='flex items-center ml-4'>
            <h3>Total cost:</h3>
            <h3 className='text-bond text-2xl ml-1' data-cy="totalCost">
              {formatMoney(props.totalCost)}

            </h3>
          </div>
        )}

      </div>

      {/* View Details */}
      <div className={`${props.isDfm ? 'block' : 'hidden'} mt-3 md:mt-0`}>
        <h5
          className="underline text-bond cursor-pointer"
          onClick={() => {
            props.setSideBar({
              holding: {} as NonCustodyHolding,
              openType: 'INVESTMENT',
            });
          }}
        >
          View details
        </h5>
      </div>
    </div>
  );
}

export default DrilldownValue;