import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { passwordRules } from '../../constants/Constants';
import LoginInput from '../Inputs/Input';
import PasswordRequirement from './PasswordRequirement';
import { Button } from 'private-wc-ui';
import useToken from '../../hooks/useToken';
import { useDependencyInjector } from '../../context/DependencyInjector';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import PasswordInput from '../Inputs/PasswordInput';

export interface PasswordModalProps {
  showPasswordModal: boolean;
  closeModal: () => void;
}

interface FormResult {
  message: string;
  success: boolean;
}

const PasswordModal = (props: PasswordModalProps) => {
  const [showModal, setShowModal] = useState(props.showPasswordModal);

  const [formRes, setFormRes] = useState<FormResult | null>(null);

  const { authService } = useDependencyInjector();

  const queryClient = useQueryClient();

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;
    if (formRes && formRes.success) {
      timer = setTimeout(() => {
        // invalidate the query and cause it to close
        queryClient.invalidateQueries(['password-new-required']);
        setFormRes(null)
      }, 3000);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [formRes]);

  useEffect(() => {
    setShowModal(props.showPasswordModal);
    if (props.showPasswordModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [props.showPasswordModal]);

  const initialValues = {
    newPassword: '',
    confirmPassword: '',
  };

  const auth = useToken();

  const isDisabledForViewAs = (): boolean => {
    return auth.token != null && auth.token.as != undefined;
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: yup.object().shape({
      newPassword: yup.string().matches(passwordRules),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref('newPassword'), undefined], 'Passwords must match')
        .required('Required'),
    }),
    onSubmit: async (values) => {
      authService
        .setNewPassword(values.newPassword)
        .then(() => {
          setFormRes({ message: 'Password updated.', success: true });
        })
        .catch(() => {
          setFormRes({
            message: 'An error occurred. Please try again.',
            success: false,
          });
        });
    },
  });

  return (
    <div
      id="passwordModal"
      tabIndex={-1}
      aria-hidden="true"
      className={`fixed inset-1 flex items-center justify-center z-[99999999999999] bg-grey-2 bg-opacity-80 ${
        showModal ? 'visible' : 'hidden'
      }`}
      onClick={props.closeModal}>
      <div
        className="relative p-4 w-full max-w-xl h-full md:h-auto bg-opacity-0"
        onClick={(event) => {
          event.stopPropagation();
        }}>
        <div className="relative p-4 text-left bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
          <button
            type="button"
            onClick={props.closeModal}
            className="text-midasLight absolute top-2.5 right-2.5 bg-transparent  hover:text-black 0 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center">
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"></path>
            </svg>
            <span className="sr-only">Close modal</span>
          </button>

          <p>Please set your new password below.</p>

          <form onSubmit={formik.handleSubmit}>
            <div className="mt-5 flex flex-col w-full">
              <label
                className="font-sans font-bold text-tuxedo text-[14px]"
                htmlFor="newPassword">
                New Password
              </label>
              <PasswordInput
                type={'password'}
                name={'newPassword'}
                value={formik.values.newPassword}
                onChange={formik.handleChange}
                disabled={
                  (auth.token != null && auth.token.group === 'Company') ||
                  isDisabledForViewAs()
                }
                error={
                  formik.errors.newPassword != undefined &&
                  formik.errors.newPassword.length != 0 &&
                  formik.touched.newPassword
                }
                id={'username'}></PasswordInput>
              <PasswordRequirement
                password={formik.values.newPassword}
                htmlFor={'newPassword'}
              />
            </div>
            <div className="mt-5 flex flex-col  w-full">
              <label
                className="font-sans font-bold text-tuxedo text-[14px]"
                htmlFor="newPassword2">
                Confirm Password
              </label>
              <PasswordInput
                type={'password'}
                name={'confirmPassword'}
                disabled={
                  (auth.token != null && auth.token.group === 'Company') ||
                  isDisabledForViewAs()
                }
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                error={
                  formik.errors.confirmPassword != undefined &&
                  formik.errors.confirmPassword.length != 0 &&
                  formik.touched.confirmPassword
                }
                id={'username'}></PasswordInput>
              {formik.errors.confirmPassword &&
              formik.touched.confirmPassword ? (
                <div style={{ color: 'red' }}>
                  {formik.errors.confirmPassword}
                </div>
              ) : null}
            </div>
            <div className="mt-5 md:w-[45%] w-full">
              <Button
                style={{ width: '100%' }}
                label="confirm change"
                disabled={
                  (auth.token != null && auth.token.group === 'Company') ||
                  isDisabledForViewAs()
                }
                theme={'primary'}
                type={'submit'}
              />
            </div>
            <div className="mt-5 md:w-[45%] w-full">
              <p
                className={`${formRes != undefined ? 'visible' : 'hidden'} ${
                  formRes != undefined && formRes.success
                    ? 'text-tuxedo'
                    : 'text-[#ff0000]'
                }`}>
                {formRes?.message}
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PasswordModal;
