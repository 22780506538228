import React, {useState} from 'react';
import LoginInput from '../Inputs/Input';
import ButtonComponent from '../Buttons/Button';
import {useDependencyInjector} from '../../context/DependencyInjector';
import {useFormik} from 'formik';
import {passwordRegexValidator} from '../../utils/HelperUtils';
import {createSearchParams, useNavigate} from "react-router-dom";
import { Button } from 'private-wc-ui';
import PasswordRequirement from '../Passwords/PasswordRequirement';
import * as yup from 'yup';
import { passwordRules } from '../../constants/Constants';
import PasswordInput from '../Inputs/PasswordInput';


interface FormProps {
    id: string;
    username: string;
}

interface FormValues {
    newPassword: string;
    confirmPassword: string;
}

interface Errors {
    newPassword?: string;
    confirmPassword?: string;
}

function PasswordForm(props: FormProps) {
    const {authService} = useDependencyInjector();
    const navigate = useNavigate()

    const initialValues: FormValues = {
        newPassword: '',
        confirmPassword: '',
    };


    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: yup.object().shape({
            newPassword: yup.string().matches(passwordRules),
            confirmPassword: yup
              .string()
              .oneOf([yup.ref('newPassword'), undefined], 'Passwords must match')
              .required('Required'),
        }),
        onSubmit: async (values) => {
            formik.setSubmitting(true);
            const {newPassword} = values;
            try {
                await authService.changePasswordWithToken(props.id, props.username, newPassword);
                formik.resetForm();
                formik.setSubmitting(false);
                navigate({
                    pathname: "/auth/conf",
                    search: createSearchParams({
                        msg_type: "pwd-complete"
                    }).toString()
                });
            } catch (e) {
                alert("Error occurred")
            }

        },
    });
    return (
        <form onSubmit={formik.handleSubmit} className={"flex-col flex justify-center items-center"} >

            <p className={'text-left'}>Please enter your new password</p>

            <div className="mt-5 flex flex-col md:w-[45%] w-full">
                <label
                    className="font-sans font-bold text-tuxedo text-[14px]"
                    htmlFor="newPassword">
                    New password
                </label>
                <PasswordInput
                    type={'password'}
                    name={'newPassword'}
                    value={formik.values.newPassword}
                    onChange={formik.handleChange}
                    error={
                        formik.errors.newPassword != undefined &&
                        formik.errors.newPassword.length != 0 &&
                        formik.touched.newPassword
                    }
                    id={'username'}></PasswordInput>

                <PasswordRequirement password={formik.values.newPassword} htmlFor={'newPassword'} />


            </div>
            <div className="mt-3 flex flex-col md:w-[45%] w-full">
                <label
                    className="font-sans font-bold text-tuxedo text-[14px]"
                    htmlFor="newPassword2">
                    Confirm password
                </label>
                <PasswordInput
                    type={'password'}
                    name={'confirmPassword'}
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    error={
                        formik.errors.confirmPassword != undefined &&
                        formik.errors.confirmPassword.length != 0 &&
                        formik.touched.confirmPassword
                    }
                    id={'username'}></PasswordInput>
                {formik.errors.confirmPassword && formik.touched.confirmPassword ? (
                    <div style={{color: 'red'}}>{formik.errors.confirmPassword}</div>
                ) : null}
            </div>
            <div className="mt-5 md:w-[45%] w-full">
                <Button
                    style={{width: '100%'}}
                    label="confirm change"
                    theme={'primary'}
                    disabled={formik.isSubmitting}
                    type={'submit'}
                />
            </div>
        </form>
    );
}

export default PasswordForm;
